<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper mt-4">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-12">
            <div class="card p-md-2 p-0">
              <div class="card-body pt-3 pb-5">
                <div class="row justify-content-center">
                  <div class="col-xl-8">
                    <h5 class="mb-2">Update Payment Code Settings</h5>
                    <div class="d-flex w-100 align-items-end mb-4">
                      <div class="w-100 mr-1">
                        <label for="">{{(paymentCodePrefix.key.replaceAll("_", " "))}}</label>
                        <input type="text" class="form-control" placeholder="" v-model="paymentCodePrefix.value">
                      </div>
                      <div>
                        <button class="btn btn-primary px-lg-4 px-2" type="submit" @click="updatePaymentCodePrefixSetting">Update</button>
                      </div>
                    </div>
                    <div class="d-flex w-100 align-items-end">
                      <div class="w-100 mr-1">
                        <label for="">{{(paymentCodeSuffix.key.replaceAll("_", " "))}}</label>
                        <input type="text" class="form-control" placeholder="" v-model="paymentCodeSuffix.value">
                      </div>
                      <div>
                        <button class="btn btn-primary px-lg-4 px-2" type="submit" @click="updatePaymentCodeSuffixSetting">Update</button>
                      </div>
                    </div>

                    <!--Payment card settings-->
                    <div>
                      <h5 class="mb-2 mt-4">Update Payment Card Settings</h5>
                      <div class="d-flex w-100 align-items-end">
                        <div class="w-50"><label for="">Card Name</label></div>
                        <div class="w-50"><label for="">Card Rate</label></div>
                      </div>
                      <div v-for="(card, index) in paymentCardSurcharge.value.details" :key="index" class="d-flex align-items-center">
                        <div class="w-100 mb-2 mr-2">
                          <input v-model="card.name" type="text" class="form-control text-capitalize" >
                        </div>
                        <div class="w-100 mb-2">
                          <input v-model="card.rate" type="text" class="form-control ml-1" >
                        </div>
                        <div>
                          <button @click="removePaymentCardSurchargeItem(index)" class="btn mb-2" ><i class='bx bx-x'></i></button>
                        </div>
                      </div>
                      <div class="text-right">
                        <button class="btn btn-primary mr-1" type="submit" @click="updatePaymentCardSetting" >Save Changes</button>
                        <button class="btn btn-info" type="submit" @click="addNewPaymentCardSurcharge">Add New</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
// appLayout
import AppLayout from '../../../../layouts/backEnd/AppLayout.vue'

// mixins
import ShowToastMessage from '@/components/backEnd/mixins/ShowToastMessage';
import Loader from '@/components/backEnd/mixins/Loader';
import Authorization from '@/components/backEnd/mixins/Authorization';
// core packages
import {
  mapActions, mapGetters,
} from "vuex";

export default {
  name: 'PaymentSetting',
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
  },
  data() {
    return {
      getSettingsParams: {
        type: ['payment'],
      },
      paymentCodePrefix:
          {
            id: '',
            key:'',
            type:'payment',
            value:''
          },

      paymentCodeSuffix:
          {
            id: '',
            key:'',
            type:'payment',
            value:''
          },
      paymentCardSurcharge:
          {
            id:'',
            key:'',
            type:'payment',
            value: []
          },
      addedPaymentCardSurcharge: {
        status: '',
        details: []
      },
    };
  },

  computed: {
    ...mapGetters({
      settingPaymentCodePrefix:"appSettings/settingPaymentCodePrefix",
      settingPaymentCodeSuffix:"appSettings/settingPaymentCodeSuffix",
      settingPaymentCardSurcharge:"appSettings/settingPaymentCardSurcharge",
    }),
  },

  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
      putSetting: "appSettings/putSetting",
    }),

    async getSettingList() {
      this.loader(true);
      const response = await this.getSettings(this.getSettingsParams);
      this.loader(false);
      if(response.status === 200){
        this.paymentCodePrefix = this.settingPaymentCodePrefix;
        this.paymentCodeSuffix = this.settingPaymentCodeSuffix;
        this.paymentCardSurcharge = this.settingPaymentCardSurcharge;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
    },

    //update prefix
    prefixValidationChecker(){
      if(!this.settingPaymentCodePrefix.value){
        this.showToastMessage({
          type:'error',
        });
      }
    },
    async updatePaymentCodePrefixSetting() {
      if(!this.prefixValidationChecker()){
        const data = {
          type: this.settingPaymentCodePrefix.type,
          key: this.settingPaymentCodePrefix.key,
          value: this.settingPaymentCodePrefix.value,
        }


        const dataObj = {
          id: this.settingPaymentCodePrefix.id,
          data: data
        }

        this.loader(true);
        const response = await this.putSetting(dataObj);
        this.loader(false);
        if (response.status === 200 ) {
          this.showToastMessage({
            type:'success',
            message:`  Updated Successfully  `
          });
          return;
        }

        if (response.message) {
          this.showToastMessage(response);
        }
      }

    },

    //update suffix
    suffixValidationChecker(){
      if(!this.paymentCodeSuffix.value){
        this.showToastMessage({
          type:'error',
        });
      }
    },
    async updatePaymentCodeSuffixSetting() {
      if(!this.suffixValidationChecker()){

        const data = {
          type: this.paymentCodeSuffix.type,
          key: this.paymentCodeSuffix.key,
          value: this.paymentCodeSuffix.value,
        }


        const dataObj = {
          id: this.paymentCodeSuffix.id,
          data: data
        }

        this.loader(true);
        const response = await this.putSetting(dataObj);
        this.loader(false);
        if (response.status === 200 ) {

          this.showToastMessage({
            type:'success',
            message:`  Updated Successfully  `
          });
          return;
        }

        if (response.message) {
          this.showToastMessage(response);
        }
      }
    },


    //Update payment card setting
    paymentCardSettingValidationChecker(){
      let isNull = false;
      this.paymentCardSurcharge.value.details.map(item => {
        if((item.name === null || item.name === "") || (item.rate === null || item.rate === "")){
          isNull = true;
        }
      })
      if(isNull){
        this.showToastMessage({
          type: 'error',
          message: `This value field can't be empty`
        });
        return isNull;
      }
    },
    async updatePaymentCardSetting() {
      if(!this.paymentCardSettingValidationChecker()){
        const data = {
          type: this.paymentCardSurcharge.type,
          key: this.paymentCardSurcharge.key,
          value: this.paymentCardSurcharge.value,
        }
        const dataObj = {
          id: this.paymentCardSurcharge.id,
          data: data
        }

        this.loader(true);
        const response = await this.putSetting(dataObj);
        this.loader(false);
        if (response.status === 200 ) {
          this.showToastMessage({
            type:'success',
            message:`  Updated Successfully  `
          });
          return;
        }

        if (response.message) {
          this.showToastMessage(response);
        }
      }
    },

    //  Add new card surcharge to the list
    addNewPaymentCardSurcharge(){
     this.paymentCardSurcharge.value.details.push({
       name:'',
       rate:''
     });
    },

    //delete Payment card surcharge
    removePaymentCardSurchargeItem(itemIndex){
      if(this.paymentCardSurcharge.value.details.length > 1){
        this.paymentCardSurcharge.value.details = this.paymentCardSurcharge.value.details.filter((_, index) => index !== itemIndex);
        return;
      }
    }
  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    this.loader(false);
  },
}
</script>

<style scoped>
</style>